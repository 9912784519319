<template>
  <b-container class="import-wizard-step04 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <h1>{{ $t('common.assets')}}</h1>
        <b-table
          stacked="md"
          striped
          hover
          show-empty
          class="spirecta-simple-table"
          :items="finalData"
          :fields="tableFields"
        >
          <template v-slot:cell(bank_account_balance)="row">
            {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency) }}
          </template>
          <template v-slot:cell(spirecta_account_balance)="row">
            {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency) }}
          </template>
          <template v-slot:cell(difference)="row">
            <div :class="'value-' + (row.value < 0 ? 'decrease' : 'increase')">{{ row.value | formatAmount(currentCOA.locale, currentCOA.currency) }}</div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'

/**
 * Last step of wizard. Jush show table with all values that user set on previous step.
 * If user agree with the result - user can perform saving changes.
 */
export default {
  name: 'UpdateValuesFromTinkWizardStep04',
  filters: { formatAmount },
  props: ['final-data'],
  data () {
    return {
      translationPath: 'assets_liabilities.update_values_from_tink_wizard.step4.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    tableFields () {
      return [
        { key: 'bank_account_name', label: this.$t(this.translationPath + 'tink_account') },
        { key: 'bank_account_balance', label: this.$t('common.balance'), class: 'text-right' },
        { key: 'spirecta_account_name', label: this.$t(this.translationPath + 'spirecta_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category')) },
        { key: 'spirecta_account_balance', label: this.$t('common.balance'), class: 'text-right' },
        { key: 'difference', label: this.$t('common.difference') },
        { key: 'offset_account_name', label: this.$t(this.translationPath + 'offset_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category')) }
      ]
    }
  },
  methods: {
    validate () {
      return true
    }
  }
}
</script>
