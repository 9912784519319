<template>
  <b-container class="import-wizard-step03 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description_' + (currentUser.strict_accounting_mode ? 'accounts' : 'categories'))"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <h1>{{ $t('common.assets')}}</h1>
        <b-table
          stacked="md"
          striped
          hover
          show-empty
          class="spirecta-simple-table"
          :items="tinkAccounts"
          :fields="tableFields"
          :busy="!isLoaded"
          :key="tableKey"
        >
          <template v-slot:table-busy>
            <loader />
          </template>
          <template v-slot:cell(name)="row">
            {{ row.item.number }}<br />({{ row.value }})
          </template>
          <template v-slot:cell(balance_tink)="row">
            {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency) }}
          </template>
          <template v-slot:cell(spirecta_account)="row">
            <b-form-select
              v-model="row.item.spirecta_account_id"
              :options="spirectaAccountOptions"
              @input="(spirectaAccountId) => onMatchChange(row.item, spirectaAccountId)"
            />
          </template>
          <template v-slot:cell(spirecta_balance)="row">
            <template v-if="row.item.spirecta_account_id">
              {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency) }}
            </template>
          </template>
          <template v-slot:cell(balance_difference)="row">
            <currency-input
              :ref="'CurrencyInput' + row.item.number"
              v-model="row.item.balance_difference"
              :disabled="!row.item.spirecta_account_id"
              @input="onDifferenceChange"
            />
          </template>
          <template v-slot:cell(offset_account_id)="row">
            <b-form-select
              v-model="row.item.offset_account_id"
              :options="offsetAccountOptions"
              :disabled="!row.item.spirecta_account_id"
              @input="(offsetAccountId) => onOffsetAccountInput(row.item, offsetAccountId)"
            />
          </template>
        </b-table>
        <!--<pre>{{ tinkAccounts }}</pre>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'
import CurrencyInput from '@/components/common/CurrencyInput'

/**
 * Third step of UpdateValuesFromTinkWizard. Here we list all asset accounts with their values,
 * Match them to Spirecta accounts and show difference between balances in bank and in Spirecta.
 * User is able to correct difference values.
 */
export default {
  name: 'UpdateValuesFromTinkWizardStep03',
  components: { Loader, CurrencyInput },
  filters: { formatAmount },
  props: ['connection'],
  data () {
    return {
      translationPath: 'assets_liabilities.update_values_from_tink_wizard.step3.',
      tinkAccounts: [],
      spirectaAccounts: [],
      spirectaCapitalAccounts: [],
      coaNetworthAccount: null,
      isLoaded: false,
      tableKey: 1 // We increase it when we want to rerender table.
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    tableFields () {
      return [
        { key: 'name', label: this.$t(this.translationPath + 'tink_account') },
        { key: 'balance_tink', label: this.$t('common.balance'), class: 'text-right' },
        { key: 'spirecta_account', label: this.$t(this.translationPath + 'spirecta_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category')) },
        { key: 'spirecta_balance', label: this.$t('common.balance'), class: 'text-right' },
        { key: 'balance_difference', label: this.$t('common.difference') },
        { key: 'offset_account_id', label: this.$t(this.translationPath + 'offset_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category')) }
      ]
    },
    spirectaAccountOptions () {
      const options = []
      this.spirectaAccounts.map(account => {
        options.push({
          text: account.title,
          value: account.id
        })
      })
      return options
    },
    offsetAccountOptions () {
      const options = []
      this.spirectaCapitalAccounts.map(account => {
        options.push({
          text: account.title,
          value: account.id
        })
      })
      return options
    }
  },
  methods: {
    init () {
      Promise.all([
        this.loadTinkAccounts(),
        this.loadSpirectaAccounts()
      ])
        .then(() => {
          this.matchAccounts()
          this.isLoaded = true
        })
        .catch(err => {
          console.error(err)
        })
    },
    validate () {
      let isAccountMatched = false
      for (const i in this.tinkAccounts) {
        if (this.tinkAccounts[i].spirecta_account_id) {
          isAccountMatched = true
          break
        }
      }

      let isAccountChanged = false
      for (const i in this.tinkAccounts) {
        if (this.tinkAccounts[i].balance_difference) {
          isAccountChanged = true
          break
        }
      }

      let isOffsetAccountsSet = true
      for (const i in this.tinkAccounts) {
        if (this.tinkAccounts[i].spirecta_account_id && !this.tinkAccounts[i].offset_account_id) {
          isOffsetAccountsSet = false
          break
        }
      }

      const isValid = isAccountMatched && isAccountChanged && isOffsetAccountsSet
      if (isValid) {
        this.$emit('validate-success')
      } else {
        this.$emit('validate-fail')
      }
      return isValid
    },
    async loadTinkAccounts () {
      this.tinkAccounts = []
      return new Promise((resolve, reject) => {
        if (!this.connection) {
          reject(new Error('No connection.'))
        }
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/accounts/${this.connection.id}`)
          .then(response => {
            this.tinkAccounts = response.data.data.db_accounts.filter(a => ['savings', 'checking', 'investment', 'pension', 'other', 'external'].indexOf(a.type.toLowerCase()) !== -1) // loan and credit_card for liabilities
            response.data.data.accounts.map(tinkAccount => {
              this.tinkAccounts.map(tinkAccountDB => {
                tinkAccountDB.spirecta_account_id = null
                tinkAccountDB.balance_difference = null
                tinkAccountDB.spirecta_balance = null
                tinkAccountDB.offset_account_id = null
                tinkAccountDB.offset_account_name = null
                if (tinkAccountDB.number === tinkAccount.accountNumber) {
                  tinkAccountDB.balance_tink = tinkAccount.balance
                }
              })
            })
            resolve(this.tinkAccounts)
          })
          .catch(err => {
            console.error(err)
          })
      })
    },
    async loadSpirectaAccounts () {
      this.spirectaAccounts = []
      return new Promise((resolve, reject) => {
        if (!this.connection) {
          reject(new Error('No connection.'))
        }
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=asset,liability,income,expense&fetch_amount=1&limit=1000`)
          .then(response => {
            this.spirectaAccounts = response.data.data.filter(account => account.type === 'asset')
            response.data.data.map(account => {
              if (account.is_capital_account || account.id === this.currentCOA.networth_account_id) {
                this.spirectaCapitalAccounts.push(account)
              }
              if (account.id === this.currentCOA.networth_account_id) {
                this.coaNetworthAccount = account
              }
            })
            resolve(this.spirectaAccounts)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    /**
     * Match tink account with spirecta account by tink id stored in spirecta account
     */
    matchAccounts () {
      this.tinkAccounts.map(tinkAccount => {
        this.spirectaAccounts.map(spirectaAccount => {
          if (spirectaAccount.tink_id === tinkAccount.tink_account_id) {
            tinkAccount.spirecta_account_id = spirectaAccount.id
            tinkAccount.spirecta_balance = spirectaAccount.amount
            tinkAccount.balance_difference = spirectaAccount.amount - tinkAccount.balance_tink
          }
        })
        this.setDefaultOffsetAccount(tinkAccount)
      })
      if (this.validate()) {
        this.emitData()
      }
    },
    /**
     * On change matched account.
     * Change spirecta balance and difference for row.
     */
    onMatchChange (item, spirectaAccountId) {
      this.spirectaAccounts.map(spirectaAccount => {
        if (spirectaAccount.id === spirectaAccountId) {
          item.spirecta_balance = spirectaAccount.amount
          item.balance_difference = spirectaAccount.amount - item.balance_tink
        }
      })
      this.setDefaultOffsetAccount(item)
      this.tableKey++
      if (this.validate()) {
        this.emitData()
      }
    },
    onDifferenceChange () {
      this.validate()
      this.emitData()
    },
    onOffsetAccountInput (item, offsetAccountId) {
      for (const i in this.spirectaCapitalAccounts) {
        if (this.spirectaCapitalAccounts[i].id === offsetAccountId) {
          item.offset_account_id = offsetAccountId
          item.offset_account_name = this.spirectaCapitalAccounts[i].title
          break
        }
      }
      this.validate()
      this.emitData()
    },
    setDefaultOffsetAccount (item) {
      for (const i in this.spirectaAccounts) {
        if (this.spirectaAccounts[i].id === item.spirecta_account_id) {
          for (const j in this.spirectaCapitalAccounts) {
            if (this.spirectaCapitalAccounts[j].id === this.spirectaAccounts[i].default_offset_account_id) {
              item.offset_account_id = this.spirectaCapitalAccounts[j].id
              item.offset_account_name = this.spirectaCapitalAccounts[j].title
              break
            }
          }
        }
        if (item.offset_account_id) {
          break
        }
      }
      if (!item.offset_account_id && this.coaNetworthAccount) {
        item.offset_account_id = this.coaNetworthAccount.id
        item.offset_account_name = this.coaNetworthAccount.title
      }
    },
    emitData () {
      const data = []
      this.tinkAccounts.map(account => {
        if (account.spirecta_account_id && account.balance_difference) {
          const item = {
            bank_account_name: account.number + '( ' + account.name + ' )',
            bank_account_balance: account.balance_tink,
            spirecta_account_id: account.spirecta_account_id,
            spirecta_account_name: null,
            spirecta_account_balance: account.spirecta_balance,
            difference: account.balance_difference,
            offset_account_id: account.offset_account_id,
            offset_account_name: account.offset_account_name
          }
          for (const i in this.spirectaAccountOptions) {
            if (this.spirectaAccountOptions[i].value === item.spirecta_account_id) {
              item.spirecta_account_name = this.spirectaAccountOptions[i].text
              break
            }
          }
          data.push(item)
        }
      })
      this.$emit('model-updated', { account_values: data })
    }
  }
}
</script>
