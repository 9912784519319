<template>
  <b-container class="update-values-from-tink-wizard bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col>
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">{{ $t(translationPath + 'title') }}</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="step-container-v2">
            <div class="stepper bg-light mx-auto text-regular">
              <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="CreateStep" @on-change="onTabChange">
                <tab-content :title="$t(translationPath + 'step_labels.step1')" :before-change="() => validateStep(1)">
                  <update-values-from-tink-wizard-step-01
                    ref="WizardStep1"
                    @model-updated="mergePartialModels"
                    @validate-success="setDisabledStatus(false, 0)"
                    @validate-fail="setDisabledStatus(true, 0)"
                  />
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step2')" :before-change="() => validateStep(2)">
                  <update-values-from-tink-wizard-step-02
                    ref="WizardStep2"
                    :connection="Object.prototype.hasOwnProperty.call(finalModel, 'connection') ? finalModel.connection : null"
                    @validate-success="setDisabledStatus(false, 1)"
                    @validate-fail="setDisabledStatus(true, 1)"
                  />
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step3')" :before-change="() => validateStep(3)">
                  <update-values-from-tink-wizard-step-03
                    ref="WizardStep3"
                    :connection="Object.prototype.hasOwnProperty.call(finalModel, 'connection') ? finalModel.connection : null"
                    @model-updated="mergePartialModels"
                    @validate-success="setDisabledStatus(false, 2)"
                    @validate-fail="setDisabledStatus(true, 2)"
                  />
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step4')" :before-change="() => validateStep(4)">
                  <update-values-from-tink-wizard-step-04
                    ref="WizardStep4"
                    :final-data="Object.prototype.hasOwnProperty.call(finalModel, 'account_values') ? finalModel.account_values : null"
                    @validate-success="setDisabledStatus(false, 3)"
                    @validate-fail="setDisabledStatus(true, 3)"
                  />
                </tab-content>
                <template slot="footer" slot-scope="props">
                  <div :class="{ hasSpace: props.activeTabIndex != 0 }">
                    <div class="wizard-footer-left">
                      <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
                        {{ $t('common.previous') }}
                      </wizard-button>
                    </div>
                    <div class="line" v-if="props.activeTabIndex != 0"></div>
                    <div class="wizard-footer-right">
                      <wizard-button tabindex="6" v-if="!props.isLastStep" @click.native="onNextClick(props)" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">
                        {{ $t('common.next') }}
                      </wizard-button>
                      <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" tabindex="5" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">{{$t('common.finalize_the_import')}}</wizard-button>
                    </div>
                  </div>
                </template>
              </form-wizard>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <success-modal
      :title="$t(translationPath+'success_modal.title')"
      :message="$t(translationPath+'success_modal.message')"
      :button="$t(translationPath+'success_modal.button_text')"
      :redirect="'/reports/other/account-plan-v2/assets'"
      :orLinkText="$t(translationPath+'success_modal.or_link_text')"
      :orLink="'/redirect?to=updateValuesTink'"
      @hide="onHideSuccessModal"
    ></success-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import SuccessModal from '@/components/modals/SuccessModal'
import UpdateValuesFromTinkWizardStep01 from './UpdateValuesFromTinkWizardStep01.vue'
import UpdateValuesFromTinkWizardStep02 from './UpdateValuesFromTinkWizardStep02.vue'
import UpdateValuesFromTinkWizardStep03 from './UpdateValuesFromTinkWizardStep03.vue'
import UpdateValuesFromTinkWizardStep04 from './UpdateValuesFromTinkWizardStep04.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

/**
 * Wizard that updates current asset values through Tink. So it loads values from user's bank
 * and sets them as current valuest in Spirecta.
 */
export default {
  name: 'UpdateValuesFromTinkWizard',
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    UpdateValuesFromTinkWizardStep01,
    UpdateValuesFromTinkWizardStep02,
    UpdateValuesFromTinkWizardStep03,
    UpdateValuesFromTinkWizardStep04,
    SuccessModal
  },
  data () {
    return {
      translationPath: 'assets_liabilities.update_values_from_tink_wizard.',
      nextDisabled: {
        0: true,
        1: true,
        2: true,
        3: false
      },
      finalModel: {}
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.manage_title'), to: '/reports/other/account-plan-v2/assets' },
        { text: this.$t(this.translationPath + 'title_assets_liabilities') },
        { text: '', to: '', active: true }
      ]
    }
  },
  methods: {
    onNextClick (props) {
      if (props.activeTabIndex === 0 && this.$refs.WizardStep1.tab === 'create-connection') {
        this.$refs.WizardStep1.$storeConnection()
          .then(() => {
            props.nextTab()
          })
      } else {
        props.nextTab()
      }
    },
    async validateStep (step) {
      const reference = this.$refs['WizardStep' + step]
      const isValid = await reference.validate()
      return isValid
    },
    setDisabledStatus (value, stepIndex) {
      this.nextDisabled[stepIndex] = value
    },
    mergePartialModels (model) {
      this.finalModel = Object.assign({}, this.finalModel, model)
      // console.log(this.finalModel)
    },
    onHideSuccessModal () {
      this.$router.push('/redirect?to=UpdateValuesTink')
    },
    /**
     * Creates transactions with entries and send them to backend api.
     */
    onComplete () {
      // console.log(this.finalModel)
      const postData = { transactions: [] }
      this.finalModel.account_values.map(transactionData => {
        postData.transactions.push({
          title: 'Update value from update tink wizard',
          date: (new Date()).toISOString().substr(0, 10),
          type: 'transfer',
          entries: [
            {
              account_id: transactionData.spirecta_account_id,
              amount: Math.abs(transactionData.difference),
              dctype: transactionData.difference < 0 ? 'dr' : 'cr'
            },
            {
              account_id: transactionData.offset_account_id,
              amount: Math.abs(transactionData.difference),
              dctype: transactionData.difference < 0 ? 'cr' : 'dr'
            }
          ]
        })
      })
      axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, postData)
        .then(response => {
          this.$bvModal.show('successModal')
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.title'), {
            title: this.$t(this.translationPath + 'toast.update_fail_text'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    onTabChange (oldIndex, newIndex) {
      if (oldIndex === 0 && newIndex === 1) {
        this.$refs.WizardStep2.init()
        // this.$refs.WizardStep2.initSkip() // For dev (skip logging to Tink, use logged connection)
      } else if (oldIndex === 1 && newIndex === 2) {
        this.$refs.WizardStep3.init()
      }

      if (this.$refs.WizardStep2) {
        this.$refs.WizardStep2.$removeEvents()
        if (newIndex === 1) {
          this.$refs.WizardStep2.$listenEvents()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
.update-values-from-tink-wizard {
  .value-decrease {
    color: #da5b5b;
  }
  .value-increase {
    color: #36b373;
  }
}
</style>
